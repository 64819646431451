import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import ContentLayout from '@/layout/ContentLayout.vue'
import { useUserStore } from '@/store/modules/user'
const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Dashboard',
    meta: {
      alwaysShow: true,
      title: '首页',
      icon: 'carbon:home'
    },
    children: [
      {
        path: 'analysis',
        component: () => import('@/views/Dashboard/Analysis.vue'),
        name: 'Analysis',
        meta: {
          title: '分析页',
          noCache: true,
          affix: true,
          icon: 'carbon:text-link-analysis'
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Root',
    meta: {
      isRoot: true,
      hidden: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/platformLogin',
    component: () => import('@/views/Login/PlatformLogin.vue'),
    name: 'PlatformLogin',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]
export const asyncRouterMap: AppRouteRecordRaw[] = []
//@ts-ignore
export const getAsyncRouterMap: AppRouteRecordRaw[] = () => {
  const user = useUserStore()
  const role = user?.userInfo?.admin?.business_id ? 'business' : 'platform'
  return [
    {
      path: '/mall',
      component: Layout,
      name: 'mall',
      meta: {
        hidden: false,
        title: '配件商城',
        icon: 'carbon:shopping-cart',
        isRoot: true
      },
      children: [
        {
          path: 'mallSetting',
          component: ContentLayout,
          meta: {
            title: '商城设置',
            icon: 'carbon:presentation-file',
            hidden: role == 'business'
          },
          children: [
            {
              path: 'mallSettingIndex',
              name: 'mallSettingIndex',
              component: () => import('@/views/mall/mallSetting/mallSetting.vue'),
              meta: {
                title: '商城设置',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'businessSetting',
              name: 'businessSetting',
              component: () => import('@/views/mall/mallSetting/businessSetting.vue'),
              meta: {
                title: '交易设置',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'goods',
          name: 'goods',
          component: () => import('@/views/mall/goods/All.vue'),
          meta: {
            title: '商品管理(总后台)',
            icon: 'carbon:product',
            hidden: role == 'business'
          },
          children: [
            {
              path: 'audit',
              name: 'goods-audit',
              component: () => import('@/views/mall/goods/Audit.vue'),
              meta: {
                title: '待审核',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'state1',
              name: 'goods-state1',
              component: () => import('@/views/mall/goods/State1.vue'),
              meta: {
                title: '出售中',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'warning',
              name: 'goods-warning',
              component: () => import('@/views/mall/goods/Warning.vue'),
              meta: {
                title: '低库存',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'state0',
              name: 'goods-state0',
              component: () => import('@/views/mall/goods/State0.vue'),
              meta: {
                title: '仓库中',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'reject',
              name: 'goods-reject',
              component: () => import('@/views/mall/goods/Reject.vue'),
              meta: {
                title: '未通过',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'trash',
              name: 'goods-trash',
              component: () => import('@/views/mall/goods/Trash.vue'),
              meta: {
                title: '回收站',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: '',
              name: 'goods-all',
              component: () => import('@/views/mall/goods/All.vue'),
              meta: {
                title: '全部',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'goodsSeller',
          name: 'goodsSeller',
          component: () => import('@/views/mall/goodsSeller/All.vue'),
          meta: {
            title: '商品管理(商家)',
            icon: 'carbon:product',
            hidden: role == 'platform'
          },
          children: [
            {
              path: '',
              name: 'goodsSeller-all',
              component: () => import('@/views/mall/goodsSeller/All.vue'),
              meta: {
                title: '全部',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'audit',
              name: 'goodsSeller-audit',
              component: () => import('@/views/mall/goodsSeller/Audit.vue'),
              meta: {
                title: '待审核',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'state1',
              name: 'goodsSeller-state1',
              component: () => import('@/views/mall/goodsSeller/State1.vue'),
              meta: {
                title: '出售中',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'warning',
              name: 'goodsSeller-warning',
              component: () => import('@/views/mall/goodsSeller/Warning.vue'),
              meta: {
                title: '低库存',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'state0',
              name: 'goodsSeller-state0',
              component: () => import('@/views/mall/goodsSeller/State0.vue'),
              meta: {
                title: '仓库中',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'reject',
              name: 'goodsSeller-reject',
              component: () => import('@/views/mall/goodsSeller/Reject.vue'),
              meta: {
                title: '未通过',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'trash',
              name: 'goodsSeller-trash',
              component: () => import('@/views/mall/goodsSeller/Trash.vue'),
              meta: {
                title: '回收站',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'goodsCate',
          name: 'goodsCate',
          component: () => import('@/views/mall/goodsCate/index.vue'),
          meta: {
            title: '商品分类',
            icon: 'carbon:product',
            hidden: role == 'business'
          }
        },
        {
          path: 'coupon',
          name: 'coupon',
          component: () => import('@/views/mall/coupon/index.vue'),
          meta: {
            title: '优惠券',
            icon: 'carbon:product',
            hidden: role == 'business'
          },
          children: [
            {
              path: '',
              name: 'couponList',
              component: () => import('@/views/mall/coupon/index.vue'),
              meta: {
                title: '优惠券列表',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'history',
              name: 'history',
              component: () => import('@/views/mall/coupon/history.vue'),
              meta: {
                title: '优惠券发放记录',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'goodsEvaluate',
          name: 'goodsEvaluate',
          component: () => import('@/views/mall/goodsEvaluate/Index.vue'),
          meta: {
            title: '商品评价管理',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'delivery',
          name: 'delivery',
          component: () => import('@/views/mall/delivery/index.vue'),
          meta: {
            title: '运费模板管理',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'platformOrder',
          component: () => import('@/views/mall/platformOrder/orderAll.vue'),
          name: 'platformOrder',
          meta: {
            title: '平台订单管理',
            icon: 'carbon:money',
            hidden: role == 'business'
          },
          children: [
            {
              path: '',
              component: () => import('@/views/mall/platformOrder/orderAll.vue'),
              meta: {
                title: '全部订单',
                icon: 'carbon:presentation-file'
              }
            },

            {
              path: 'platformOrder20',
              name: 'platformOrder20',
              component: () => import('@/views/mall/platformOrder/order20.vue'),
              meta: {
                title: '待付款',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'platformOrder30',
              name: 'platformOrder30',
              component: () => import('@/views/mall/platformOrder/order30.vue'),
              meta: {
                title: '待发货',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'platformOrder40',
              name: 'platformOrder40',
              component: () => import('@/views/mall/platformOrder/order40.vue'),
              meta: {
                title: '待收货',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'platformOrder50',
              name: 'platformOrder50',
              component: () => import('@/views/mall/platformOrder/order50.vue'),
              meta: {
                title: '待评价',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'platformOrder60',
              name: 'platformOrder60',
              component: () => import('@/views/mall/platformOrder/order60.vue'),
              meta: {
                title: '售后',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'platformOrder70',
              name: 'platformOrder70',
              component: () => import('@/views/mall/platformOrder/order70.vue'),
              meta: {
                title: '取消',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'platformOrder80',
              name: 'platformOrder80',
              component: () => import('@/views/mall/platformOrder/order80.vue'),
              meta: {
                title: '待审核(已微信支付)',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'platformOrder90',
              name: 'platformOrder90',
              component: () => import('@/views/mall/platformOrder/order90.vue'),
              meta: {
                title: '待审核(线下支付)',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'order',
          name: 'order',
          component: ContentLayout,
          meta: {
            title: '商家订单管理',
            icon: 'carbon:money',
            hidden: role == 'platform'
          },
          children: [
            {
              path: '',
              name: 'order',
              component: () => import('@/views/mall/order/orderAll.vue'),
              meta: {
                title: '全部订单',
                icon: 'carbon:presentation-file'
              }
            },

            {
              path: 'order20',
              name: 'order20',
              component: () => import('@/views/mall/order/order20.vue'),
              meta: {
                title: '待付款',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'order30',
              name: 'order30',
              component: () => import('@/views/mall/order/order30.vue'),
              meta: {
                title: '待发货',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'order40',
              name: 'order40',
              component: () => import('@/views/mall/order/order40.vue'),
              meta: {
                title: '待收货',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'order50',
              name: 'order50',
              component: () => import('@/views/mall/order/order50.vue'),
              meta: {
                title: '待评价',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'order60',
              name: 'order60',
              component: () => import('@/views/mall/order/order60.vue'),
              meta: {
                title: '售后',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'order70',
              name: 'order70',
              component: () => import('@/views/mall/order/order70.vue'),
              meta: {
                title: '取消',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'order80',
              name: 'order80',
              component: () => import('@/views/mall/order/order80.vue'),
              meta: {
                title: '待审核(已微信支付)',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'order90',
              name: 'order90',
              component: () => import('@/views/mall/order/order90.vue'),
              meta: {
                title: '待审核(线下支付)',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'placeOrder',
          name: 'platformOrderIndex',
          component: () => import('@/views/mall/placeOrder/placeOrder.vue'),
          meta: {
            title: '代客下单',
            icon: 'carbon:presentation-file',
            hidden: role == 'business'
          }
        },
        {
          path: 'businessTag',
          name: 'businessTag',
          component: () => import('@/views/mall/businessTag/index.vue'),
          meta: {
            title: '仓库类目管理',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'brand',
          name: 'brand',
          component: () => import('@/views/mall/brands/index.vue'),
          meta: {
            title: '品牌管理(总后台)',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'banner',
          name: 'banner',
          component: () => import('@/views/mall/banner/Index.vue'),
          meta: {
            title: '商城轮播图管理',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'sms',
          name: 'sms',
          component: () => import('@/views/mall/sms/Index.vue'),
          meta: {
            title: '短信模板管理',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'smsSendList',
          name: 'smsSendList',
          component: () => import('@/views/mall/sms/sendList.vue'),
          meta: {
            title: '发送短信任务',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'smsSendStatus',
          name: 'smsSendStatus',
          component: () => import('@/views/mall/sms/sendStatus.vue'),
          meta: {
            title: '短信发送状态',
            icon: 'carbon:money',
            hidden: true
          }
        },
        {
          path: 'brandSeller',
          name: 'brandSeller',
          component: () => import('@/views/mall/brandSeller/index.vue'),
          meta: {
            title: '品牌管理(商家)',
            icon: 'carbon:money',
            hidden: role == 'platform'
          }
        },
        {
          path: 'business',
          name: 'business',
          component: () => import('@/views/mall/business/index.vue'),
          meta: {
            title: '仓库管理',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'serviceGuarantee',
          name: 'serviceGuarantee',
          component: () => import('@/views/mall/serviceGuarantee/index.vue'),
          meta: {
            title: '服务保障',
            icon: 'carbon:money',
            hidden: role == 'business'
          }
        },
        {
          path: 'expressSetting',
          component: import('@/views/mall/expressSetting/masterSetting.vue'),
          name: 'expressMasterSetting',
          meta: {
            title: '包邮设置',
            icon: 'carbon:presentation-file',
            hidden: role == 'business'
          },
          children: [
            {
              path: 'expressMasterSettingIndex',
              component: () => import('@/views/mall/expressSetting/masterSetting.vue'),
              name: 'expressMasterSettingIndex',
              meta: {
                title: '师傅包邮设置',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'warehouseSetting',
              name: 'mallWarehouseSetting',
              component: () => import('@/views/mall/expressSetting/warehouseSetting.vue'),
              meta: {
                title: '网点包邮设置',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        }
      ]
    },
    //权限
    {
      path: '/permission',
      component: Layout,
      redirect: '/permission/role',
      name: 'permission',
      meta: {
        hidden: role == 'business',
        title: '系统权限',
        icon: 'carbon:settings',
        isRoot: true
      },
      children: [
        {
          path: 'role',
          name: 'role',
          component: () => import('@/views/permission/role/role.vue'),
          meta: {
            title: '权限（开发用）',
            icon: 'carbon:user'
          }
        },
        {
          path: 'group',
          name: 'role_group',
          component: () => import('@/views/permission/role/group.vue'),
          meta: {
            title: '权限菜单（开发用）',
            icon: 'carbon:user'
          }
        },
        {
          path: 'auth',
          name: 'role_auth',
          component: () => import('@/views/permission/role/index.vue'),
          meta: {
            title: '角色权限组',
            icon: 'carbon:user'
          }
        },
        {
          path: 'integralList',
          name: 'integralList',
          component: () => import('@/views/permission/member/integralList.vue'),
          meta: {
            title: '积分列表',
            icon: 'carbon:user',
            hidden: true
          }
        },
        {
          path: 'member',
          name: 'mallSetting',
          component: () => import('@/views/permission/member/index.vue'),
          meta: {
            title: '会员列表',
            icon: 'carbon:user-access'
          }
        },
        {
          path: 'address',
          name: 'addressList',
          component: () => import('@/views/permission/member/address.vue'),
          meta: {
            title: '会员地址列表',
            icon: 'carbon:user-access',
            hidden: true
          }
        }
      ]
    },
    {
      path: '/setting',
      component: Layout,
      redirect: '/setting/imageVideoLimit',
      name: 'setting',
      meta: {
        title: '综合设置',
        icon: 'carbon:home',
        isRoot: true
      },
      children: [
        {
          path: 'imageVideoLimit',
          name: 'imageVideoLimit',
          component: () => import('@/views/setting/imageLimit/index.vue'),
          meta: {
            title: '图片视频大小设置',
            icon: 'carbon:home',
            hidden: role == 'business'
          }
        },
        {
          path: 'fault',
          name: 'fault',

          component: () => import('@/views/setting/fault/index.vue'),
          meta: {
            title: '故障查询设置',
            icon: 'carbon:home',
            hidden: role == 'business'
          }
        },
        {
          path: 'settingDelivery',
          name: 'settingDelivery',

          component: () => import('@/views/setting/delivery/index.vue'),
          meta: {
            title: '快递列表',
            hidden: role == 'business',
            icon: 'carbon:home'
          }
        },
        {
          path: 'settingDeliveryConfig',
          name: 'settingDeliveryConfig',

          component: () => import('@/views/setting/delivery/config.vue'),
          meta: {
            title: '物流动态配置',
            hidden: role == 'business',
            icon: 'carbon:home'
          }
        },
        {
          path: 'settingDeliveryQuick',
          name: 'settingDeliveryQuick',

          component: () => import('@/views/setting/delivery/quick.vue'),
          meta: {
            title: '快递快捷标签列表',
            hidden: role == 'business',
            icon: 'carbon:home'
          }
        },
        {
          path: 'agreement',
          component: () => import('@/views/setting/agreements/service.vue'),
          name: 'agreement',

          meta: {
            title: '协议管理',
            hidden: role == 'business',
            icon: 'carbon:presentation-file'
          },
          children: [
            //service_agreement    服务协议  ,privacy_agreement 隐私协议   ,business_agreement 商家入驻协议
            {
              path: '',
              name: 'serviceAgreement',
              component: () => import('@/views/setting/agreements/service.vue'),
              meta: {
                title: '服务协议',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'privacy',
              name: 'privacyAgreement',
              component: () => import('@/views/setting/agreements/privacy.vue'),
              meta: {
                title: '隐私协议',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'business',
              name: 'businessAgreement',
              component: () => import('@/views/setting/agreements/business.vue'),
              meta: {
                title: '商家入驻协议',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'release',
              name: 'releaseAgreement',
              component: () => import('@/views/setting/agreements/release.vue'),
              meta: {
                title: '发布供应协议',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'AfAgreement',
              name: 'AfAgreement',
              component: () => import('@/views/setting/agreements/afAgreement.vue'),
              meta: {
                title: '售后保障协议',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'notice',
          component: ContentLayout,
          name: 'notice',
          meta: {
            title: '公告管理',
            icon: 'carbon:presentation-file',
            hidden: role == 'business'
          },
          children: [
            //1 商品详情 2 仓库后台  3 商城主页  4  仓库主页  5 故障查询  6内部公告
            {
              path: 'goods',
              name: 'goodsNotice',
              component: () => import('@/views/setting/notices/goods.vue'),
              meta: {
                title: '商品详情公告',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'warehouse',
              name: 'warehouseHouse',
              component: () => import('@/views/setting/notices/warehouse.vue'),
              meta: {
                title: '仓库后台公告',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'mallHome',
              name: 'mallHome',
              component: () => import('@/views/setting/notices/mallHome.vue'),
              meta: {
                title: '商城主页公告',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'warehouseHome',
              name: 'warehouseHome',
              component: () => import('@/views/setting/notices/warehouseHome.vue'),
              meta: {
                title: '仓库主页公告',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'faultQuery',
              name: 'faultQueryNotice',
              component: () => import('@/views/setting/notices/faultQuery.vue'),

              meta: {
                title: '故障查询公告',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'innerNotice',
              name: 'innerNotice',
              component: () => import('@/views/setting/notices/innerNotice.vue'),
              meta: {
                title: '内部公告',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        },
        {
          path: 'goodsEvaluateConfig',
          name: 'goodsEvaluateConfig',
          component: () => import('@/views/setting/goodsEvaluateConfig/config.vue'),
          meta: {
            title: '商品评价管理',
            hidden: role == 'business',
            icon: 'carbon:money'
          }
        },
        {
          path: 'server',
          name: 'server',
          component: () => import('@/views/setting/server/server.vue'),
          meta: {
            title: '客服管理设置',
            hidden: role == 'business',
            icon: 'carbon:home'
          }
        },
        {
          path: 'storage',
          name: 'storage',
          component: ContentLayout,
          redirect: '/setting/storage/fileList',
          meta: {
            title: '云存储',
            icon: 'carbon:home'
          },
          children: [
            {
              path: 'fileList',
              component: () => import('@/views/setting/yunStorage/index.vue'),
              name: '文件列表',
              meta: {
                title: '文件列表',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'groupList',
              name: '分组列表',
              component: () => import('@/views/setting/yunStorage/group.vue'),
              meta: {
                title: '分组列表',
                icon: 'carbon:presentation-file'
              }
            },
            {
              path: 'settingOss',
              component: () => import('@/views/setting/yunStorage/oss.vue'),
              name: 'settingOss',
              meta: {
                title: 'OSS设置',
                hidden: role == 'business',
                icon: 'carbon:presentation-file'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/faultInquiry',
      component: Layout,
      redirect: '/faultInquiry/brand',
      name: 'FaultInquiry',
      meta: {
        title: t('router.faultInquiry'),
        icon: 'carbon:skill-level-advanced',
        isRoot: true,
        hidden: role == 'business'
      },
      children: [
        {
          path: 'brand',
          name: 'Brand',
          component: () => import('@/views/FaultInquiry/Brand/Index.vue'),
          meta: {
            title: '故障查询分类',
            icon: 'ic:outline-branding-watermark'
          }
        },
        {
          path: 'querycode',
          name: 'QueryCode',
          component: () => import('@/views/FaultInquiry/QueryCode/Index.vue'),
          meta: {
            title: '故障查询代码',
            icon: 'carbon:query'
          }
        },
        {
          path: 'datasharingclass',
          name: 'DataSharingClass',
          component: () => import('@/views/FaultInquiry/DataSharingClass/Index.vue'),
          meta: {
            title: '技术资料分类',
            icon: 'ic:twotone-query-stats'
          }
        },
        {
          path: 'datasharing',
          name: 'DataSharing',
          component: () => import('@/views/FaultInquiry/DataSharing/Index.vue'),
          meta: {
            title: '技术资料分享',
            icon: 'carbon:presentation-file'
          }
        },
        {
          path: 'carouselchart',
          name: 'CarouselChart',
          component: () => import('@/views/FaultInquiry/CarouselChart/Index.vue'),
          meta: {
            title: '轮播图',
            icon: 'lucide:image-play'
          }
        },
        {
          path: 'journal',
          name: 'Journal',
          component: () => import('@/views/FaultInquiry/Journal/Index.vue'),
          meta: {
            title: '故障更新日志',
            icon: 'carbon:ibm-watson-knowledge-catalog'
          }
        },
        {
          path: 'feedback',
          name: 'Feedback',
          component: ContentLayout,
          meta: {
            title: '用户反馈',
            icon: 'carbon:ibm-watson-knowledge-catalog'
          },
          children: [
            {
              path: 'dataFeedBack',
              name: 'dataFeedBack',
              component: () => import('@/views/FaultInquiry/Feedback/DataFeedback.vue'),
              meta: {
                title: '资料分享反馈',
                icon: 'carbon:ibm-watson-knowledge-catalog'
              }
            },
            {
              path: 'codingFeedback',
              name: 'codingFeedback',
              component: () => import('@/views/FaultInquiry/Feedback/CodeFeedback.vue'),
              meta: {
                title: '查询代码反馈',
                icon: 'carbon:ibm-watson-knowledge-catalog'
              }
            }
          ]
        },
        {
          path: 'dustbinList',
          name: 'dustbinList',
          component: ContentLayout,
          meta: {
            title: '回收站',
            icon: 'carbon:ibm-watson-knowledge-catalog'
          },
          children: [
            {
              path: 'dataDustbinList',
              name: 'dataDustbinList',
              component: () => import('@/views/FaultInquiry/Dustbin/DataShareDustbin.vue'),
              meta: {
                title: '资料分享',
                icon: 'carbon:ibm-watson-knowledge-catalog'
              }
            },
            {
              path: 'codingDustbinList',
              name: 'codingDustbinList',
              component: () => import('@/views/FaultInquiry/Dustbin/QueryCodeDustbin.vue'),
              meta: {
                title: '查询代码',
                icon: 'carbon:ibm-watson-knowledge-catalog'
              }
            }
          ]
        },
        {
          path: 'uploadData',
          name: 'uploadData',
          component: () => import('@/views/FaultInquiry/UploadData/index.vue'),
          meta: {
            title: '上传资料',
            icon: 'carbon:ibm-watson-knowledge-catalog'
          }
        }
      ]
    },
    {
      path: '/admin/index',
      component: Layout,
      name: 'Admin',
      meta: {
        title: '管理员管理',
        icon: 'carbon:home',

        isRoot: true,
        hidden: role == 'business'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/admin/index.vue'),
          name: 'adminIndex',
          meta: {
            title: '管理员管理',
            noCache: true,
            affix: true,
            icon: 'carbon:text-link-analysis'
          }
        }
      ]
    },
    {
      path: '/businessInfo',
      component: Layout,
      name: 'businessInfo',
      meta: {
        title: '仓库信息',
        icon: 'carbon:home',
        isRoot: true,
        hidden: role == 'platform'
      },
      children: [
        {
          path: 'businessInfo',
          component: () => import('@/views/BusinessInfo/BusinessInfo.vue'),
          name: 'businessInfo',
          meta: {
            title: '仓库信息',
            noCache: true,
            affix: true,
            icon: 'carbon:text-link-analysis'
          }
        }
      ]
    }
  ]
}

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root', 'PlatformLogin']

  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
