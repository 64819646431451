// import type { Plugin } from 'vue'

import { ElMessage } from 'element-plus'
import type { ValidateFieldsError } from 'async-validator'
import { useClipboard } from '@vueuse/core'

/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any
  comp.install = (app: any) => {
    app.component(comp.name || comp.displayName, component)
    if (alias) {
      app.config.globalProperties[alias] = component
    }
  }
  return component as T & Plugin
}

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str: string): string => {
  if (!str) return ''
  return str.replace(/\-(\w)/g, (_, letter: string) => {
    return letter.toUpperCase()
  })
}

/**
 * 驼峰转横杠
 */
export const humpToDash = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

export const setCssVar = (prop: string, val: any, dom = document.documentElement) => {
  dom.style.setProperty(prop, val)
}

export const getCssVar = (prop: string, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(prop)
}

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
// eslint-disable-next-line
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
  if (ary.findIndex) {
    return ary.findIndex(fn)
  }
  let index = -1
  ary.some((item: T, i: number, ary: Array<T>) => {
    const ret: T = fn(item, i, ary)
    if (ret) {
      index = i
      return ret
    }
  })
  return index
}

export const trim = (str: string) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date | number | string, fmt: string) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  }
}

/**
 * 生成随机字符串
 */
export function toAnyString() {
  const str: string = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c: string) => {
    const r: number = (Math.random() * 16) | 0
    const v: number = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString()
  })
  return str
}

/**
 * 首字母大写
 */
export function firstUpperCase(str: string) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

/**
 * 把对象转为formData
 */
export function objToFormData(obj: Recordable) {
  const formData = new FormData()
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key])
  })
  return formData
}

export const showFieldsError = (fields: ValidateFieldsError | undefined) => {
  if (!fields) return
  for (const key in fields) {
    return ElMessage.error(fields[key][0].message)
  }
}
export const useMyCopy = (content: string, successTxt?: string, failTxt?: string) => {
  if (content === '') return ElMessage.error('复制内容不能为空')
  const { copy } = useClipboard({ legacy: true })
  copy(content)
    .then(() => {
      ElMessage.success(successTxt ?? '复制成功')
    })
    .catch(() => {
      ElMessage.error(failTxt ?? '复制失败')
    })
}

export const useImageSize = async (url: string | File) =>
  await new Promise<{ w: number; h: number }>((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve({ w: img.width, h: img.height })
    }
    img.onerror = function () {
      reject({ w: img.width, h: img.height })
    }
    if (typeof url === 'string') {
      img.src = url
    } else {
      const reader = new FileReader()
      reader.onload = function (e) {
        img.src = e.target?.result as string
      }
      reader.onerror = function () {
        reject({ width: img.width, height: img.height })
      }
      reader.readAsDataURL(url)
    }
  })
export const downFile = (url: string, name?: string) => {
  // 用a标签模拟点击下载文件
  // const a = document.createElement('a')
  console.info(name)
  url = url.replace(
    'http://wdhpj123.oss-cn-hangzhou.aliyuncs.com/',
    'https://backend.hpj123.com/oss/'
  )
  window.open(url, '_blank')
  // a.href = url
  // a.download = name
  // document.body.appendChild(a)
  // a.click()
  // document.body.removeChild(a)
}
export const hidePhone = (phone: string) => {
  return phone?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}
export const getAddress = (address: any = {}) => {
  debugger
  return `${address.province}${address.city}${address.area}${address.region}${address.address}`
}

export const formatNumber = (num: number) => {
  //@ts-ignore
  if (num == '') {
    return ''
  }
  let str = `${num}`.replace(/^[0~9,.]/g, '')
  const index = str.indexOf('.')
  if (index >= 1) {
    str = str.slice(0, index + 3)
  } else {
    str
  }
  //@ts-ignore
  if (str * 1 < 0) {
    return 1
  } else {
    return str
  }
}

export const getPriceObj = (sku, level) => {
  // 10 是师傅 20 网点
  const price = sku.map((item) => {
    return level == 10 ? parseFloat(item.master_price) : parseFloat(item.branch_price)
  })
  const linePrice = sku.map((item) => parseFloat(item.line_price))
  const obj = {
    minPrice: Math.min(...price)
      .toFixed(2)
      .split('.'),
    maxPrice: Math.max(...price)
      .toFixed(2)
      .split('.'),
    hasMaxPrice: Math.max(...price) != Math.min(...price),
    hasLineMaxPrice: Math.max(...linePrice) != Math.min(...linePrice),
    maxLinePrice: Math.max(...linePrice).toFixed(2),

    minLinePrice: Math.min(...linePrice).toFixed(2)
  }
  return obj
}
