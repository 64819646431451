/**
 * 请求成功状态码
 */
export const SUCCESS_CODE = 200

/**
 * 请求contentType
 */
export const CONTENT_TYPE: AxiosContentType = 'application/json'

/**
 * 请求超时时间
 */
export const REQUEST_TIMEOUT = 60000

/**
 * 不重定向白名单
 */
export const NO_REDIRECT_WHITE_LIST = ['/login', '/platformLogin']

/**
 * 不重置路由白名单
 */
export const NO_RESET_WHITE_LIST = ['Redirect', 'Login', 'NoFind', 'Root', 'platformLogin']

/**
 * 表格默认过滤列设置字段
 */
export const DEFAULT_FILTER_COLUMN = ['expand', 'selection']

/**
 * 是否根据headers->content-type自动转换数据格式
 */
export const TRANSFORM_REQUEST_DATA = true
//1 商品详情 2 仓库后台  3 商城主页  4  仓库主页  5 故障查询 6内部公告
export type NoticeTypeValue = '1' | '2' | '3' | '4' | '5' | '6'
export const NOTICE_TYPE = {
  1: '商品详情',
  2: '仓库后台',
  3: '商城主页',
  4: '仓库主页',
  5: '故障查询',
  6: '内部公告'
}
// 1 小程序 2 公众号 3 百度小程序
export const NOTICE_URL_TYPE = {
  1: '小程序',
  2: '公众号',
  3: '百度小程序'
}

// 1 小程序 2 公众号3  百度小程序 4 普通地址
export const CarouselUrlTypeValue = {
  1: '内部小程序',
  2: '外部小程序'
}
export const BannerType = {
  1: '首页公告',
  2: '仓库公告'
}
// 10,20,30,40,50,60,70,80,90  全部，待付款，待发货，待收货，待评价，售后,取消,待审核 待付款（线下支付）
export const PlatformOrderStatus = {
  10: '全部',
  20: '待付款',
  30: '待发货',
  40: '待收货',
  50: '待评价',
  60: '售后',
  70: '取消',
  80: '待审核',
  90: '待付款（线下支付）'
}
// 20,30,40,50,60,70,80,-1 待付款，待发货，待收货，待评价，售后,取消,待审核,状态异常
export const OrderStatus = {
  20: '待付款',
  30: '待发货',
  40: '待收货',
  50: '待评价',
  60: '售后',
  70: '取消',
  80: '待审核(已微信支付 )',
  90: '待审核(已线下支付)',
  '-1': '状态异常'
}
// 1 普通快递 2 顺丰到付 3 快递到付
export const ExpressModel = {
  1: '普通快递',
  2: '顺丰到付',
  3: '快递到付'
}
// 10 师傅 20 网点
export const memberLevels = {
  10: '师傅',
  20: '网点'
}
export const payType = {
  10: '微信',
  20: '线下'
}
export const OrderType = {
  1: '在线下单',
  2: '代客下单'
}
//
export const TagsList = {
  1: '一类',
  2: '二类'
}
//1 后端路由 2 前端路由 3 前端组件
export const RouteType = {
  1: '后端路由',
  2: '前端路由',
  3: '前端组件'
}

export const BusinessType = {
  1: '企业',
  2: '个人'
}

export const SMS_SEN_TYPE = {
  8: '全部',
  1: '部分会员',
  2: '手动输入',
  3: '会员标签',
  4: '师傅',
  5: '网点',
  6: '未下单的会员',
  7: '新注册的会员'
}

export const MEMBER_BIND_TYPE = {
  1: '已绑定',
  0: '未绑定'
}
export const FILTER_TYPE = [
  { label: '图片', value: 'picture' },
  { label: '视频', value: 'video' },
  { label: '音频', value: 'audio' },
  { label: '其他', value: 'other' }
]
// 0/1/2  待审核 / 审核通过/审核不通过
export const BRAND_EXA_STATE = {
  0: '待审核',
  1: '审核通过',
  2: '审核不通过'
}
